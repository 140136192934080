<template>
    <div id="contents" class="contents vmd_channel_page">
        <div class="content_head">
          <!-- s: 페이지 타이틀 -->
          <div class="page_ttl_wrap has_btn">
            <h2 class="page_ttl">채널 관리</h2>
            <div class="btn_group">
              <button type="button" class="btn strong2 w_m h_m" @click="publish">발행하기</button>
            </div>
          </div>
          <!-- e: 페이지 타이틀 -->
        </div>

        <!-- s: content_body -->
        <div class="content_body">
            <!-- s: input_sec -->
            <div class="input_sec">
              <!-- s: table -->
              <div class="tbl_wrap list form">
                <table class="tbl">
                  <caption></caption>
                  <tbody>
                  <tr>
                    <th class="">전시 시간</th>
                    <td class="">
                      <div class="input_area select inline">
                        <select name="" v-model.number="startHour">
                          <option v-for="(time) in 24" :value="time-1">{{time-1}}</option>
                        </select>
                        <div class="input_txt">시 부터</div>
                      </div>
                      <div class="input_area select inline">
                        <select name="" id="hourEnd" v-model.number="endHour">
                          <option v-for="(time) in 24" :value="time">{{time}}</option>
                        </select>
                        <div class="input_txt">시 까지</div>
                      </div>
                      <div class="btn_area inline">
                        <button @click="saveExhibitHour" type="button" class="btn strong h_s w_sm">저장
                        </button>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <!-- e: table -->
            </div>
            <div class="board_sec channel_list">
                <div class="btn_area clearFix mb10">
                    <button class="btn strong h_s w_sm" @click="redirect('/channel/create')">채널 추가</button>
                </div>
                <!-- s: table -->
                <div class="tbl_wrap list">
                    <!-- 게시글 있는 경우 (10개씩 노출) -->
                    <table class="tbl" v-if="channels && Object.keys(channels).length > 0">
                        <caption>멤버</caption>
                        <colgroup>
                            <col width="150"/>
                            <col/>
                            <col width="150"/>
                            <col width="150"/>
                            <col width="150"/>
                            <col width="150"/>
                        </colgroup>
                        <thead>
                        <tr>
                            <th>채널 번호</th>
                            <th class="title">채널명</th>
                            <th class="subject">VMD 수</th>
                            <th>재생시간</th>
                            <th>등록일시</th>
                            <th>최종 수정일시</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, hash) in channels">
                            <td>
                                <span>채널 {{item.no}}</span>
                            </td>
                            <td class="title" @click="redirect('/channel/modify/' + hash)">
                                <span><a href="javascript:void(0)">{{item.name}}</a></span>
                            </td>
                            <td>
                                <span>{{item.vmd ? item.vmd.length : 0}}</span>
                            </td>
                            <td>
                                <span>{{item.vmd ? getTotalPlayTime(item.vmd) : 0}}</span>
                            </td>
                            <td>
                                <span>{{item.creationDateTime}}</span>
                            </td>
                            <td>
                                <span>{{item.lastModifiedDateTime}}</span>
                            </td>
                        </tr>
                        <!--                        <tr>
                                                    <td>
                                                        <span>채널 1</span>
                                                    </td>
                                                    <td>
                                                        <span>만능체험대 전용 VMD</span>
                                                    </td>
                                                    <td>
                                                        <span>4</span>
                                                    </td>
                                                    <td>
                                                        <span>2’ 30”</span>
                                                    </td>
                                                    <td>
                                                        <span>YY.MM.DD hh:mm:ss</span>
                                                    </td>
                                                    <td>
                                                        <span>YY.MM.DD hh:mm:ss</span>
                                                    </td>
                                                </tr>-->
                        </tbody>
                    </table>
                    <!-- 검색시 해당 게시글 없는 경우 -->
                    <div v-show="!channels || Object.keys(channels).length <= 0" class="no_result">
                        <p>검색 결과가 없습니다.</p>
                    </div>
                </div>
                <!-- e: table -->
            </div>
            <!-- e: board_sec -->
        </div>
        <!-- e: content_body -->
    </div>
</template>

<script>
import VMD from '../../../plugins/firebase/model/vmd';
import moment from "moment";

export default {
    name: "firebase/test",
    layout: {
        name: 'admin',
    },
    data() {
        return {
            vmd_root_key: 'vmd',
            channel_root_key: 'channel',
            channel_setting_key: 'setting/channel',
            channel_publish_key: 'publish/channel',
            vmd: {},
            channels: {},
            selected: '',
            startHour: 0,
            endHour: 24,
        }
    },
    methods: {
        saveExhibitHour() {
            //startHour, endHour 저장.
            if(this.startHour === this.endHour){
                this.toastDanger('같은 시간을 설정할 수 없습니다.');
                return;
            }
            if(this.startHour > this.endHour){
                this.toastDanger('시작시간이 종료시간보다 앞에 존재할 수 없습니다.');
                return;
            }
            this.$firebase.database().ref(this.channel_setting_key).child('exhibitStartHour').set(this.startHour);
            this.$firebase.database().ref(this.channel_setting_key).child('exhibitEndHour').set(this.endHour);
            this.toastSuccess('전시 시간 저장 완료.');
        },
        publish(){
            this.$firebase.database().ref(this.channel_publish_key).child('lastPublishDateTime').set(this.timeToString(moment()));
            this.toastSuccess('발행 완료.');
        },
        getTotalPlayTime(vmdHashList) {
            let seconds = 0;
            for (const hash of vmdHashList) {
                let vmd = new VMD();
                let data = this.vmd[hash];
                vmd.setDefaultInfo(data);
                seconds += vmd.filesPlayTime;
            }
            if (seconds) {
                let hour = Math.floor(seconds / 3600);
                let min = Math.floor((seconds % 3600) / 60);
                let sec = Math.floor(seconds % 60); //Dev:210923 hayeon 소수점 안나오게 수정
                return `${hour ? hour + '° ' : ''}${min ? min + '’ ' : ''}${sec ? sec + '”' : ''}`;
            }
            return '0”';
        },
        /*push() {
            this.$firebase.database().ref(this.channel_root_key).push(new VMD())
        },
        update() {
            if (this.selected) {
                const item = this.vmd[this.selected];
                console.log('item', item);
                item.name = '변경2';
                this.$firebase.database().ref(this.channel_root_key).child(this.selected).set(item);
            }

        },
        read() {
            this.$firebase.database().ref(this.channel_root_key).once('value', (sn) => {
                console.log(sn.val());
                this.vmd = sn.val();
            })
        },
        remove() {
            this.$firebase.database().ref(this.channel_root_key).child(this.selected).remove();
        },
        test() {
            let vmd = new VMD();
            console.log(vmd);
        }*/
    },
    created() {
        this.$firebase.database().ref(this.channel_setting_key).on('value', (sn) => {
            let settings = sn.val();
            if(settings){
                if(settings.exhibitStartHour){
                    this.startHour = settings.exhibitStartHour;
                }
                if(settings.exhibitEndHour){
                    this.endHour = settings.exhibitEndHour;
                }
            }
        })

        this.$firebase.database().ref(this.vmd_root_key).on('value', (sn) => {
            this.vmd = sn.val() || {};
        })
        this.$firebase.database().ref(this.channel_root_key).on('value', (sn) => {
            this.channels = sn.val() || {};
        })
    }
}
</script>

<style scoped>

</style>